import React, { useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { ImageDataLike } from "gatsby-plugin-image";
import { SectionContainer, SectionHeading } from "../../containers";
import { Link } from "../../links";
import { RichText } from "../../textFields";
import { getPathname, removeWhitespaces } from "../../utils";
import {
  AccordionContainer,
  ImageContainerDesktop,
  ImageMobile,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledImage,
} from "./styles";

type PpImageAccordion = {
  data: Array<{
    ctaLabel?: string;
    ctaUrl?: {
      url?: string;
    };
    description: string;
    icon: {
      alt: string;
      urlSharp: ImageDataLike;
    };
    title: string;
  }>;
  description: string;
  sectionName?: string;
  title: string;
};

export function ImageAccordion({
  data,
  description,
  sectionName,
  title,
}: PpImageAccordion): React.ReactElement | null {
  const [expanded, setExpanded] = useState<string>(data[0].title);
  if (data.length === 0) return null;

  function handleChange(
    _: React.SyntheticEvent,
    isExpanded: boolean,
    itemTitle: string
  ): void {
    if (expanded === itemTitle) return;
    if (isExpanded) setExpanded(itemTitle);
  }

  return (
    <SectionContainer>
      <Stack alignItems="center">
        <SectionHeading
          description={description}
          sectionName={sectionName}
          textAlign="center"
          title={title}
        />
        <AccordionContainer container spacing={8}>
          <Grid item md={6} sx={{ display: { md: "grid", xs: "none" } }}>
            <ImageContainerDesktop>
              {data.map((d) => (
                <StyledImage
                  alt={d.icon.alt}
                  classNames={expanded !== d.title ? "hidden" : ""}
                  src={d.icon.urlSharp}
                />
              ))}
            </ImageContainerDesktop>
          </Grid>
          <Grid item md={6}>
            {data.map((d) => (
              <StyledAccordion
                key={d.title}
                disableGutters
                square
                elevation={0}
                expanded={expanded === d.title}
                onChange={(
                  _: React.SyntheticEvent,
                  isExpanded: boolean
                ): void => handleChange(_, isExpanded, d.title)}
              >
                <StyledAccordionSummary
                  aria-controls={`${removeWhitespaces(d.title)}-content`}
                  id={`${removeWhitespaces(d.title)}-id`}
                >
                  <Typography color="primary" variant="h3">
                    {d.title}
                  </Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  <RichText
                    gutterBottom
                    color="primary"
                    text={d.description}
                    variant="body1"
                  />
                  {d.ctaLabel && d.ctaUrl?.url ? (
                    <Link
                      highlighted
                      color="primary"
                      to={getPathname(d.ctaUrl.url)}
                      variant="caption"
                    >
                      {d.ctaLabel}
                    </Link>
                  ) : null}
                  <ImageMobile alt={d.icon.alt} src={d.icon.urlSharp} />
                </StyledAccordionDetails>
              </StyledAccordion>
            ))}
          </Grid>
        </AccordionContainer>
      </Stack>
    </SectionContainer>
  );
}
