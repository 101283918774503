import { Breakpoint, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { findLast } from "ramda";

export function useResponsive(): <T extends unknown>(
  responsiveValues: Partial<Record<Breakpoint, T>>
) => T | undefined {
  const theme = useTheme();

  const matches = {
    lg: useMediaQuery(theme.breakpoints.up("lg")),
    md: useMediaQuery(theme.breakpoints.up("md")),
    sm: useMediaQuery(theme.breakpoints.up("sm")),
    xl: useMediaQuery(theme.breakpoints.up("xl")),
    xs: useMediaQuery(theme.breakpoints.up("xs")),
  };

  // CS: return type is actually declared above
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (responsiveValues) => {
    const match = findLast(
      (breakpoint) =>
        matches[breakpoint] && responsiveValues[breakpoint] != null,
      theme.breakpoints.keys
    );

    return match && responsiveValues[match];
  };
}
