import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { SectionHeading } from "../../containers";

export const StyledAccordion = styled(Accordion)`
  ${({ theme }): SerializedStyles => css`
    background: transparent !important;
    &::before {
      display: none;
    }
    &.MuiAccordion-root ~ &.MuiAccordion-root {
      .MuiAccordionSummary-root::before {
        position: absolute;
        left: 0;
        top: -1px;
        right: 0;
        height: 1px;
        content: "";
        opacity: 0.2;
        background-color: ${theme.palette.primary.main};
      }
    }
  `}
`;

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  ${({ theme }): SerializedStyles => css`
    color: ${theme.palette.site.accordion.expandIcon};
  `}
`;

export const Heading = styled(SectionHeading)`
  ${({ theme }): SerializedStyles => css`
    margin: 0 auto ${theme.spacing(3)};
    max-width: 550px;
  `}
`;

export const Question = styled(Typography)`
  width: 80%;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  ${({ theme }): SerializedStyles => css`
    .MuiAccordionSummary-content {
      margin: ${theme.spacing(2, 0)};
    }
  `}
`;

export const AccordionContainer = styled("div")`
  ${({ theme }): SerializedStyles => css`
    ${theme.cssMixins.colVCentered};
    max-width: 800px;
    margin: 0 auto;
  `}
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  ${({ theme }): SerializedStyles => css`
    padding: ${theme.spacing(1, 2, 3)};
  `}
`;

export const LinkWrapper = styled("div")`
  ${({ theme }): SerializedStyles => css`
    margin-top: ${theme.spacing(3)};
  `}
`;
