import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { ToggleBtnGroup } from "../toggle";
import { Package, PpPackage } from "./Package";
import { Heading, PackageWrapper, ToggleBtnGroupContainer } from "./styles";

type TpCategory = {
  label: string;
  name: string;
  packages: Array<string>;
};

type TpPackage = PpPackage & { navigationName: string };

type TpCategoryPackage = {
  [categoryName: string]: TpPackage;
};

type PpPackages = {
  categories: Array<TpCategory>;
  darkMode?: boolean;
  description: string;
  packages: Array<TpPackage>;
  sectionName?: string;
  title: string;
};

export function Packages({
  categories,
  darkMode = false,
  description,
  packages,
  sectionName,
  title,
  ...rest
}: PpPackages): React.ReactElement | null {
  const [currentCategoryPackage, setCurrentCategoryPackage] =
    useState<TpCategoryPackage>(
      categories.reduce(
        (acc, c) => ({
          ...acc,
          [c.name]: packages.find((p) => p.navigationName === c.packages[0]),
        }),
        {}
      )
    );
  const [currentCategory, setCurrentCategory] = useState<TpCategory>(
    categories[0]
  );

  if (packages.length === 0) return null;

  function handleCategoryChange(newValue: string): void {
    const newCategory = categories.find(
      (c: TpCategory): boolean => c.name === newValue
    );
    if (newCategory) {
      setCurrentCategory(newCategory);
    }
  }

  function handlePackageChange(
    categoryName: string,
    packageName: string
  ): void {
    const newPackage = packages.find(
      (p: TpPackage): boolean => p.navigationName === packageName
    );
    if (newPackage) {
      setCurrentCategoryPackage({
        ...currentCategoryPackage,
        [categoryName]: newPackage,
      });
    }
  }

  return (
    <Stack alignItems="center" spacing={{ lg: 6, xs: 4 }} {...rest}>
      <Heading
        description={description}
        sectionName={sectionName}
        textAlign="center"
        title={title}
      />
      <Stack spacing={6} sx={{ display: { lg: "flex", xs: "none" } }}>
        {categories.length > 1 && (
          <ToggleBtnGroupContainer>
            <ToggleBtnGroup
              value={currentCategory.name}
              values={categories.map((c) => ({ label: c.label, name: c.name }))}
              onChange={handleCategoryChange}
            />
          </ToggleBtnGroupContainer>
        )}
        <Stack direction="row" justifyContent="center" spacing={3}>
          {packages
            .filter(
              (pack) =>
                currentCategory.packages.indexOf(pack.navigationName) >= 0
            )
            .map((p) => (
              <Package key={p.title} {...p} darkMode={darkMode} />
            ))}
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        spacing={6}
        sx={{ display: { lg: "none", xs: "flex" } }}
      >
        {categories.map((category) => (
          <Stack key={category.label} alignItems="center" spacing={2}>
            <Stack alignItems="center">
              <Typography
                align="center"
                color="primary"
                component="p"
                variant="caption--xl"
              >
                {category.label}
              </Typography>
              <Typography
                gutterBottom
                align="center"
                color="primary"
                component="h2"
                variant="h2"
              >
                {category.name}
              </Typography>
            </Stack>
            <ToggleBtnGroupContainer>
              <ToggleBtnGroup
                value={currentCategoryPackage[category.name].navigationName}
                values={packages
                  .filter(
                    (p) => category.packages.indexOf(p.navigationName) >= 0
                  )
                  .map((p) => ({ name: p.navigationName }))}
                onChange={(newPackage): void =>
                  handlePackageChange(category.name, newPackage)
                }
              />
            </ToggleBtnGroupContainer>
            <PackageWrapper>
              <Package
                key={currentCategoryPackage[category.name].title}
                {...currentCategoryPackage[category.name]}
                darkMode
              />
            </PackageWrapper>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
