import React from "react";
import {
  Addons,
  bookACallIFrame,
  Button,
  ContactForm,
  Differences,
  formatDate,
  Founder,
  getPathname,
  Hero,
  ImageAccordion,
  Introduction,
  Link,
  Metrics,
  Packages,
  Process,
  SectionContainer,
  TestimonialWall,
  TextAccordion,
  TransitionalCTA,
  useStore,
} from "@italymondo/core-ui";
import { graphql } from "gatsby";
import { Layout, SEO } from "../../components";
import { ServiceTemplateQuery } from "./index.generated";

type PpServicePage = {
  data: ServiceTemplateQuery;
  location?: {
    pathname?: string;
  };
};

export default function ServicePage({
  data: {
    cms: { site, testimonials },
  },
  location,
}: PpServicePage): React.ReactElement | null {
  const { showAlertBar } = useStore();

  if (!site?.page) {
    return <Layout />;
  }

  const alertBarData = site.page.alertBar?.message
    ? {
        link: {
          text: site.page.alertBar?.anchorText || "",
          url: getPathname(site.page.alertBar?.page.url) || "",
        },
        text: site.page.alertBar?.message || "",
      }
    : null;

  function handleEmailSubmit(
    e: React.FormEvent<HTMLInputElement>,
    value: string
  ): void {
    e.preventDefault();
    // eslint-disable-next-line no-console
    console.log("submission: ", value);
  }

  const categoriesData = [];

  if (site.page.firstCategoryPackages.length !== 0)
    categoriesData.push({
      label: site.page.firstCategoryTitle,
      name: site.page.firstCategorySubtitle,
      packages: site.page.firstCategoryPackages.map(
        (p) => p.package.navigationName
      ),
    });
  if (site?.page?.secondCategoryPackages?.length !== 0)
    categoriesData.push({
      label: site.page.secondCategoryTitle,
      name: site.page.secondCategorySubtitle,
      packages: site?.page?.secondCategoryPackages?.map(
        (p) => p.package.navigationName
      ),
    });

  const packagesData = [
    ...site.page.firstCategoryPackages.map(({ label, package: pack }) => ({
      badgeText: label?.name,
      description: pack.description,
      features: pack.features.map((f) => ({
        availability: f.availability,
        name: f.feature.name,
        tooltip: f.hoverText,
      })),
      navigationName: pack.navigationName,
      price:
        pack.price2Value > 0
          ? [
              {
                label: pack.price1Heading,
                tooltip: pack.price1HoverText || "",
                value: pack.price1Value,
              },
              {
                label: pack.price2Heading,
                tooltip: pack.price2HoverText || "",
                value: pack.price2Value,
              },
            ]
          : {
              label: pack.price1Heading,
              tooltip: pack.price1HoverText || "",
              value: pack.price1Value,
            },
      primaryCTA: {
        text: pack.ctaText,
        url: getPathname(pack.ctaLink.url),
      },
      secondaryCTA: {
        popupDescription: pack.tertiaryCtaPopupDescription,
        popupTitle: pack.tertiaryCtaPopupTitle,
        text: pack.tertiaryCtaText,
      },
      tier: pack.tier,
      title: pack.name,
    })),
    ...site.page.secondCategoryPackages.map(({ label, package: pack }) => ({
      badgeText: label?.name,
      description: pack.description,
      features: pack.features.map((f) => ({
        availability: f.availability,
        name: f.feature.name,
        tooltip: f.hoverText,
      })),
      navigationName: pack.navigationName,
      price:
        pack.price2Value > 0
          ? [
              {
                label: pack.price1Heading,
                tooltip: pack.price1HoverText || "",
                value: pack.price1Value,
              },
              {
                label: pack.price2Heading,
                tooltip: pack.price2HoverText || "",
                value: pack.price2Value,
              },
            ]
          : {
              label: pack.price1Heading,
              tooltip: pack.price1HoverText || "",
              value: pack.price1Value,
            },
      primaryCTA: {
        text: pack.ctaText,
        url: getPathname(pack.ctaLink.url),
      },
      secondaryCTA: {
        popupDescription: pack.tertiaryCtaPopupDescription,
        popupTitle: pack.tertiaryCtaPopupTitle,
        text: pack.tertiaryCtaText,
      },
      tier: pack.tier,
      title: pack.name,
    })),
  ];

  const testimonialsArray = testimonials?.map((t) => ({
    date: formatDate(t.date || ""),
    message: t.shortMessage,
    name: t.name,
    photo: {
      alt: t.photo.alt,
      src: t.photo.urlSharp,
    },
    source: {
      alt: t?.sourceDetail?.logo?.title,
      src: t?.sourceDetail?.logo?.url,
      url: t?.sourceDetail?.url,
    },
  }));

  return (
    <Layout alertBar={alertBarData} showProgressBar={site.page.progressBar}>
      <SEO
        description={site.page?.listingSummary || ""}
        image={site.page?.listingImage?.url}
        location={location}
        title={site.page?.listingTitle || ""}
      />
      <Hero
        description={site.page.heroDescription || ""}
        hasAlertBar={showAlertBar ? alertBarData : null}
        headline={site.page.heroHeadline || ""}
        heroType={site.page.heroType}
        imageSrc={site.page.heroImage?.urlSharp || ""}
        primaryButton={
          site.page?.heroPrimaryButton
            ? {
                title: site.page?.heroPrimaryButton?.title,
                url: getPathname(site.page?.heroPrimaryButton?.page?.url),
              }
            : null
        }
        secondaryButton={
          site.page?.heroSecondaryButton
            ? {
                title: site.page?.heroSecondaryButton?.title,
                url: getPathname(site.page?.heroSecondaryButton?.page?.url),
              }
            : null
        }
        verticalText={site.page.heroVerticalText?.name || ""}
      />
      <Introduction
        media={site.page.introMedia[0]}
        mediaPlacement={site.page.introMediaPlacement}
        paragraph={site.page.introParagraph || ""}
        shadowHeader={site.page.introShadowHeader || ""}
      />
      <ImageAccordion
        data={site.page.features || []}
        description={site.page.featureDescription || ""}
        sectionName={site.page.featureSectionName || ""}
        title={site.page.featureTitle || ""}
      />
      <SectionContainer>
        <Packages
          categories={categoriesData}
          description={site.page.pricingSubtitle || ""}
          packages={packagesData}
          sectionName={site.page.pricingSectionName || ""}
          title={site.page.pricingTitle || ""}
        />
        <Addons
          addons={site.page.addons?.map(({ addon }) => ({
            name: addon.name,
            price: addon.price,
          }))}
          ctaLink={{
            intro: site.page.addonsCtaIntro,
            text: site.page.addonsCtaText,
            url: getPathname(site.page.addonsCtaLink?.url),
          }}
          title={site.page.addonsTitle}
        />
      </SectionContainer>
      <TextAccordion
        ctaLink={site.page.faqsCta}
        data={site.page.faqs.map((f) => f.faq)}
        description={site.page.faqsDescription || ""}
        sectionName={site.page.faqsSectionName || ""}
        title={site.page.faqsTitle}
      />
      <Founder
        actions={
          <Link to={getPathname(site?.page?.aboutCta?.page?.url)}>
            <Button color="primary" variant="contained">
              {site?.page?.aboutCta?.title}
            </Button>
          </Link>
        }
        alignCenter={false}
        description={site.page.aboutDescription || ""}
        media={site.page?.aboutMedia[0]}
        mediaPlacement={site.page?.aboutMediaPlacement}
        sectionName={site.page.aboutSectionName || ""}
        title={site.page.aboutTitle || ""}
      />
      <Metrics
        withoutPaddingTop
        metrics={site.page.metrics.map((m) => m.metric) || []}
      />
      <Differences
        darkBackground
        description={site.page.differenceDescription || ""}
        differences={[
          {
            data: site.page.differences.map((d) => d.difference),
          },
        ]}
        sectionName={site.page.differenceSectionName || ""}
        title={site.page.differenceTitle || ""}
      />
      <TransitionalCTA
        action={site.page.transitionalCta.action[0]}
        buttonText={site.page.transitionalCta.buttonText}
        description={site.page.transitionalCta?.description || ""}
        popupContent={bookACallIFrame}
        shadowImage={site.page.transitionalCta?.shadowImage?.url}
        title={site.page.transitionalCta?.title || ""}
        onSubmit={handleEmailSubmit}
      />
      <Process
        ctaLink={site.page.process?.cta}
        description={site.page.process.description}
        media={site.page.processMedia[0]}
        mediaPlacement={site.page.processMediaPlacement}
        sectionName={site.page.processSectionName || ""}
        steps={site.page.process.steps.map((step) => ({
          description: step.description,
          shadowHeader: step.step,
          title: step.name,
        }))}
        title={site.page.process.title}
      />
      {/* <TestimonialWall
        description={site.page.testimonialsIntro?.description}
        sectionName={site.page.testimonialsSectionName || ""}
        testimonials={testimonialsArray}
        title={site.page.testimonialsIntro?.title}
      /> */}
      <ContactForm
        confirmationPageUrl={
          getPathname(site.page?.footerContactForm?.confirmationPage?.url) ||
          "/"
        }
        description={site.page?.footerContactForm?.description || ""}
        sectionName={site.page?.contactFormSectionName || ""}
        title={site.page?.footerContactForm?.title || ""}
      />
    </Layout>
  );
}

export const query = graphql`
  query ServiceTemplate($slug: String) {
    cms {
      site(hostname: "www.italymondo.com") {
        page(slug: $slug) {
          ... on CMS_IMServicePage {
            aboutDescription
            aboutCta {
              page {
                url
              }
              title
            }
            aboutMedia {
              ... on CMS_MediaImagesBlock {
                __typename
                images {
                  image {
                    alt
                    url
                    urlSharp {
                      childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, width: 600)
                      }
                    }
                  }
                }
              }
              ... on CMS_TestimonialsBlock {
                __typename
                testimonials {
                  testimonial {
                    date
                    longMessage
                    name
                    photo {
                      alt
                      url
                      urlSharp {
                        childImageSharp {
                          gatsbyImageData(
                            height: 60
                            width: 60
                            placeholder: BLURRED
                          )
                        }
                      }
                    }
                    sourceDetail {
                      logo {
                        title
                        url
                      }
                      url
                    }
                  }
                }
              }
            }
            aboutMediaPlacement
            aboutSectionName
            aboutTitle
            addons {
              addon {
                name
                price
                term
              }
            }
            addonsCtaIntro
            addonsCtaLink {
              url
            }
            addonsCtaText
            addonsTitle
            alertBar {
              anchorText
              message
              page {
                url
              }
            }
            contactFormSectionName
            differenceDescription
            differenceSectionName
            differenceTitle
            differences {
              difference {
                description
                icon {
                  url
                }
                title
              }
            }
            faqsCta {
              page {
                url
              }
              title
            }
            faqsDescription
            faqsSectionName
            faqsTitle
            faqs {
              faq {
                answer
                question
              }
            }
            featureDescription
            featureSectionName
            featureTitle
            features {
              ctaUrl {
                url
              }
              ctaLabel
              description
              icon {
                alt
                url
                urlSharp {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      placeholder: BLURRED
                      width: 700
                    )
                  }
                }
              }
              title
            }
            firstCategoryPackages {
              label {
                name
              }
              package {
                ctaLink {
                  url
                }
                ctaText
                description
                features {
                  availability
                  feature {
                    name
                  }
                  hoverText
                }
                name
                navigationName
                price1Heading
                price1HoverText
                price1Value
                price2Heading
                price2HoverText
                price2Value
                tertiaryCtaIntro
                tertiaryCtaPopupDescription
                tertiaryCtaPopupTitle
                tertiaryCtaText
                tier
              }
            }
            firstCategorySubtitle
            firstCategoryTitle
            footerContactForm {
              confirmationPage {
                url
              }
              description
              title
            }
            heroDescription
            heroHeadline
            heroImage {
              alt
              url
              urlSharp {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            heroPrimaryButton {
              page {
                url
              }
              title
            }
            heroSecondaryButton {
              page {
                url
              }
              title
            }
            heroType
            heroVerticalText {
              name
            }
            introMedia {
              ... on CMS_MediaImagesBlock {
                __typename
                images {
                  image {
                    alt
                    url
                    urlSharp {
                      childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, width: 900)
                      }
                    }
                  }
                }
              }
              ... on CMS_TestimonialsBlock {
                __typename
                testimonials {
                  testimonial {
                    date
                    longMessage
                    name
                    photo {
                      alt
                      url
                      urlSharp {
                        childImageSharp {
                          gatsbyImageData(
                            height: 60
                            width: 60
                            placeholder: BLURRED
                          )
                        }
                      }
                    }
                    sourceDetail {
                      logo {
                        title
                        url
                      }
                      url
                    }
                  }
                }
              }
            }
            introMediaPlacement
            introParagraph
            introShadowHeader
            listingImage {
              url
            }
            listingSummary
            listingTitle
            metrics {
              metric {
                name
                value
              }
            }
            pricingTitle
            pricingSubtitle
            pricingSectionName
            process {
              cta {
                page {
                  url
                }
                title
              }
              description
              steps {
                description
                name
                step
              }
              title
            }
            processMedia {
              ... on CMS_MediaImagesBlock {
                __typename
                images {
                  image {
                    alt
                    url
                    urlSharp {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          width: 650
                          height: 550
                        )
                      }
                    }
                  }
                }
              }
              ... on CMS_TestimonialsBlock {
                __typename
                testimonials {
                  testimonial {
                    date
                    longMessage
                    name
                    photo {
                      alt
                      url
                      urlSharp {
                        childImageSharp {
                          gatsbyImageData(
                            height: 60
                            width: 60
                            placeholder: BLURRED
                          )
                        }
                      }
                    }
                    sourceDetail {
                      logo {
                        title
                        url
                      }
                      url
                    }
                  }
                }
              }
            }
            processMediaPlacement
            processSectionName
            progressBar
            secondCategoryPackages {
              label {
                name
              }
              package {
                ctaLink {
                  url
                }
                ctaText
                description
                features {
                  availability
                  feature {
                    name
                  }
                  hoverText
                }
                name
                navigationName
                price1Heading
                price1HoverText
                price1Value
                price2Heading
                price2HoverText
                price2Value
                tertiaryCtaIntro
                tertiaryCtaPopupDescription
                tertiaryCtaPopupTitle
                tertiaryCtaText
                tier
              }
            }
            secondCategorySubtitle
            secondCategoryTitle
            transitionalCta {
              action {
                ... on CMS_CollectEmailBlock {
                  __typename
                  collectEmail
                }
                ... on CMS_LinkPageBlock {
                  __typename
                  page {
                    url
                  }
                }
                ... on CMS_ShowPopupBlock {
                  __typename
                  description
                  title
                }
              }
              buttonText
              description
              shadowImage {
                url
              }
              title
            }
            transitionalCtaLabel {
              name
            }
            testimonialsIntro {
              description
              title
            }
            testimonialsSectionName
          }
        }
      }
      testimonials(service: $slug, showOnTestimonialWall: true) {
        date
        name
        photo {
          alt
          url
          urlSharp {
            childImageSharp {
              gatsbyImageData(height: 57, width: 57, placeholder: BLURRED)
            }
          }
        }
        shortMessage
        sourceDetail {
          logo {
            title
            url
          }
          url
        }
      }
    }
  }
`;
