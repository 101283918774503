import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { CheckCircle, InfoOutlined } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { RichText } from "../../textFields";
import { shouldForwardProp } from "../../utils";

type PpContainer = {
  $darkMode: boolean;
};

export const Container = styled("div", { shouldForwardProp })<PpContainer>`
  ${({ $darkMode, theme }): SerializedStyles => css`
    ${theme.cssMixins.toggleInAnimation({ duration: 300 })};
    flex: 1 1 0px;
    max-width: 500px;
    position: relative;
    overflow: hidden;
    background-color: ${$darkMode
      ? theme.palette.site.packages.darkBg
      : theme.palette.site.packages.lightBg};
    border-radius: ${theme.shape.siteBorderRadius.lg};
    box-shadow: ${theme.shadows[1]};
    padding: ${theme.spacing(5, 3, 4)};
    .package-divider {
      border-top: 1px dashed ${theme.palette.primary.main};
      width: 100%;
    }
  `}
`;

type PpHasFeatures = {
  $hasFeatures: boolean;
};

export const TextContainer = styled(Stack, {
  shouldForwardProp,
})<PpHasFeatures>`
  ${({ $hasFeatures }): SerializedStyles => css`
    height: ${$hasFeatures ? "220px" : "270px"};
  `}
`;

export const Description = styled(RichText, {
  shouldForwardProp,
})<PpHasFeatures>`
  ${({ $hasFeatures, theme }): SerializedStyles => css`
    ${theme.cssMixins.lineClamp({ lines: $hasFeatures ? 5 : 7 })};
  `}
`;

export const FeaturesContainer = styled(Stack)`
  width: 100%;
`;

export const CTALink = styled("div")`
  ${({ theme }): SerializedStyles => css`
    position: absolute;
    bottom: ${theme.spacing(5)};
  `}
`;

type PpIsDisabled = {
  $isDisabled: boolean;
};

export const Badge = styled(Typography)`
  ${({ theme }): SerializedStyles => css`
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 0 0 ${theme.shape.siteBorderRadius.xs};
    max-height: 30px;
    padding: ${theme.spacing(0.625, 2.25)};
    background-color: ${theme.palette.secondary.main};
  `}
`;

export const Feature = styled(Stack)`
  height: 35px;
`;

export const FeatureName = styled(Typography, {
  shouldForwardProp,
})<PpIsDisabled>`
  ${({ $isDisabled }): SerializedStyles => css`
    opacity: ${$isDisabled ? "0.35" : "1"};
  `}
`;

export const InfoIcon = styled(InfoOutlined)`
  ${({ theme }): SerializedStyles => css`
    color: ${theme.palette.site.shadowHeader.dark};
  `}
`;

export const CheckIcon = styled(CheckCircle, {
  shouldForwardProp,
})<PpIsDisabled>`
  ${({ $isDisabled, theme }): SerializedStyles => css`
    max-height: 35px;
    color: ${theme.palette.primary.light};
    opacity: ${$isDisabled ? "0.15" : "1"};
  `}
`;

type PpPriceBox = {
  $hasTooltip: boolean;
  $isMultiple: boolean;
};

export const PriceBox = styled(Stack, { shouldForwardProp })<PpPriceBox>`
  ${({ $hasTooltip, $isMultiple, theme }): SerializedStyles => css`
    background-color: ${$isMultiple
      ? theme.palette.background.default
      : "none"};
    padding: ${theme.spacing(1, 2)};
    border-radius: ${$isMultiple ? theme.shape.siteBorderRadius.sm : "0"};
    border: ${$isMultiple ? `1px solid #EAEEF7` : "0"};
    cursor: ${$hasTooltip ? "help" : "default"};
  `}
`;

export const PriceContainer = styled(Stack)`
  height: 65px;
`;

export const BookACallLabel = styled(Typography)`
  cursor: pointer;
`;
