import React from "react";
import { Stack, Typography } from "@mui/material";
import { Button } from "../../buttons";
import { Link } from "../../links";
import { Tooltip } from "../../tooltip";
import {
  Badge,
  CheckIcon,
  Container,
  Description,
  Feature,
  FeatureName,
  FeaturesContainer,
  InfoIcon,
  PriceBox,
  PriceContainer,
  TextContainer,
} from "./styles";

type TpPrice = {
  label: string;
  tooltip?: string;
  value: string;
};

export type PpPackage = {
  badgeText?: string;
  darkMode: boolean;
  description: string;
  features: Array<{
    availability: "not_available" | "available" | "";
    name: string;
    tooltip?: string;
  }>;
  price: TpPrice | Array<TpPrice>;
  primaryCTA: {
    text: string;
    url: string;
  };
  secondaryCTA: {
    popupDescription: string;
    popupTitle: string;
    text: string;
  };
  tier: string;
  title: string;
};

// secondaryCTA temporarely commented out
export function Package({
  badgeText,
  darkMode,
  description,
  features,
  price,
  primaryCTA,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  secondaryCTA,
  tier,
  title,
}: PpPackage): React.ReactElement {
  const hasFeatures = features.length > 0;
  const textColor = darkMode ? "primary.contrastText" : "primary";
  return (
    <Container $darkMode={darkMode}>
      {badgeText && (
        <Badge color="primary.contrastText" variant="badge">
          {badgeText}
        </Badge>
      )}
      <Stack alignItems="center" spacing={3}>
        <TextContainer $hasFeatures={hasFeatures}>
          {tier && (
            <Typography
              gutterBottom
              align="center"
              color={darkMode ? "primary.contrastText" : "primary.light"}
              variant="h4"
            >
              {tier}
            </Typography>
          )}
          <Typography
            gutterBottom
            align="center"
            color={darkMode ? "primary.light" : "primary"}
            variant="h3"
          >
            {title}
          </Typography>
          <Description
            $hasFeatures={hasFeatures}
            align="center"
            color={textColor}
            text={description}
            variant="body1"
          />
        </TextContainer>
        <PriceContainer alignItems="center" direction="row" spacing={2}>
          {Array.isArray(price) ? (
            price.map((p) => (
              <Tooltip key={p.value} arrow placement="top" title={p.tooltip}>
                <PriceBox
                  $isMultiple
                  $hasTooltip={p.tooltip !== ""}
                  alignItems="center"
                >
                  <Typography
                    gutterBottom
                    color={textColor}
                    variant="label--sml"
                  >
                    {p.label}
                  </Typography>
                  <Typography color={textColor} variant="h4">
                    &#36;{p.value.toLocaleString()}
                  </Typography>
                </PriceBox>
              </Tooltip>
            ))
          ) : (
            <Tooltip
              key={price.value}
              arrow
              placement="top"
              title={price.tooltip}
            >
              <PriceBox
                $hasTooltip={price.tooltip !== ""}
                $isMultiple={false}
                alignItems="center"
              >
                <Typography gutterBottom color={textColor} variant="label">
                  {price.label}
                </Typography>
                <Typography color={textColor} variant="h3">
                  &#36;{price.value.toLocaleString()}
                </Typography>
              </PriceBox>
            </Tooltip>
          )}
        </PriceContainer>
        <Link to={primaryCTA.url}>
          <Button color="secondary" variant="contained">
            {primaryCTA.text}
          </Button>
        </Link>
        {hasFeatures && (
          <React.Fragment>
            <div className="package-divider" />
            <FeaturesContainer spacing={1}>
              {features.map(({ availability, name, tooltip }) => (
                <Feature
                  key={name}
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                >
                  <Stack alignItems="center" direction="row" spacing={1}>
                    <FeatureName
                      $isDisabled={availability === "not_available"}
                      color={textColor}
                      component="p"
                      variant="h5"
                    >
                      {name}
                    </FeatureName>
                    {tooltip && (
                      <Tooltip arrow placement="top" title={tooltip}>
                        <InfoIcon fontSize="small" />
                      </Tooltip>
                    )}
                  </Stack>
                  {availability !== "" && (
                    <CheckIcon
                      $isDisabled={availability === "not_available"}
                      fontSize="large"
                    />
                  )}
                </Feature>
              ))}
            </FeaturesContainer>
          </React.Fragment>
        )}
      </Stack>
    </Container>
  );
}
