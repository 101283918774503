/* eslint-disable sort-keys-fix/sort-keys-fix */
import React from "react";
import { Stack, Typography } from "@mui/material";
import { Link } from "../links";
import { RichText } from "../textFields";
import {
  Addon,
  AddonsContainer,
  CardContainer,
  Container,
  Divider,
} from "./styles";

type PpAddons = {
  addons: Array<{
    name: string;
    price: number;
  }>;
  ctaLink: {
    intro: string;
    text: string;
    url: string;
  };
  title: string;
};

export function Addons({
  addons,
  ctaLink,
  title,
}: PpAddons): React.ReactElement | null {
  if (Array.isArray(addons) && addons.length === 0) return null;
  return (
    <CardContainer>
      <Container alignItems="center" justifyContent="space-between" spacing={6}>
        <RichText align="center" color="primary" text={title} variant="h3" />
        <AddonsContainer
          direction={{ xs: "column", md: "row" }}
          divider={<Divider flexItem orientation="vertical" />}
          spacing={{ xs: 1, md: 6 }}
        >
          {addons.map((addon) => (
            <Addon
              key={addon.name}
              alignItems="center"
              direction={{ xs: "row", md: "column" }}
              justifyContent={{ xs: "space-between", md: "flex-start" }}
              spacing={1}
            >
              <Typography align="center" color="primary.light" variant="h4">
                {addon.name}
              </Typography>
              <Typography align="center" color="primary" variant="h3">
                &#36;{addon.price.toLocaleString()}
              </Typography>
            </Addon>
          ))}
        </AddonsContainer>
        <Stack alignItems="center" spacing={2}>
          <Typography align="center" color="primary" variant="addons--intro">
            {ctaLink.intro}
          </Typography>
          <Link
            highlighted
            align="center"
            color="primary"
            to={ctaLink.url}
            variant="caption"
          >
            {ctaLink.text}
          </Link>
        </Stack>
      </Container>
    </CardContainer>
  );
}
