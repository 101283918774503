import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Divider as MuiDivider, Stack } from "@mui/material";

export const CardContainer = styled("div")`
  ${({ theme }): SerializedStyles => css`
    max-width: 400px;
    height: fit-content;
    margin: ${theme.spacing(10, "auto", 0)};
    padding: ${theme.spacing(4, 3)};
    background-color: ${theme.palette.site.addons.background};
    border-radius: ${theme.shape.siteBorderRadius.lg};
    border: ${theme.shape.siteBorder.addons};
    box-shadow: ${theme.shadows[1]};
    ${theme.breakpoints.up("md")} {
      margin: ${theme.spacing(4, "auto", 0)};
      padding: ${theme.spacing(6, 22)};
      max-width: 900px;
    }
  `}
`;

export const Container = styled(Stack)`
  height: 100%;
`;

export const AddonsContainer = styled(Stack)`
  ${({ theme }): SerializedStyles => css`
    ${theme.breakpoints.down("md")} {
      width: 90%;
    }
  `}
`;

export const Addon = styled(Stack)`
  min-width: 120px;
`;

export const Divider = styled(MuiDivider)`
  ${({ theme }): SerializedStyles => css`
    border-style: dashed;
    border-color: ${theme.palette.primary.main};
  `}
`;
