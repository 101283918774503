import React from "react";
import { Tooltip as MuiTooltip, TooltipProps } from "@mui/material";

export function Tooltip({
  children,
  title,
  ...rest
}: TooltipProps): React.ReactElement {
  return (
    <MuiTooltip
      enterTouchDelay={0}
      leaveTouchDelay={3000}
      title={title || ""}
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
}
