import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import { Image } from "../../image";

export const AccordionContainer = styled(Grid)`
  ${({ theme }): SerializedStyles => css`
    margin-top: ${theme.spacing(-2)};
    ${theme.breakpoints.up("md")} {
      margin-top: ${theme.spacing(0)};
    }
  `}
`;

export const ImageContainerDesktop = styled("div")`
  height: 100%;
  position: relative;
`;

export const StyledImage = styled(Image)`
  ${({ theme }): SerializedStyles => css`
    position: absolute !important;
    ${theme.cssMixins.stretchAll};
    .styled-image {
      position: absolute;
      opacity: 1;
      transition: ${theme.transitions.create("opacity")};
      height: 100%;
      width: 100%;
      &.hidden {
        opacity: 0 !important;
      }
    }
  `}
`;

export const ImageMobile = styled(Image)`
  ${({ theme }): SerializedStyles => css`
    margin-top: ${theme.spacing(2)};
    max-height: 180px;
    ${theme.breakpoints.up("md")} {
      display: none !important;
    }
  `}
`;

export const StyledAccordion = styled(Accordion)`
  ${({ theme }): SerializedStyles => css`
    background: transparent !important;
    &::before {
      display: none;
    }
    &:last-of-type {
      margin-bottom: ${theme.spacing(3)} !important;
    }
  `}
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  ${({ theme }): SerializedStyles => css`
    .MuiAccordionSummary-content {
      margin: ${theme.spacing(4, 0, 1)};
      &.Mui-expanded {
        > h3 {
          opacity: 1;
        }
      }
      &:not(.Mui-expanded) {
        > h3 {
          transition: ${theme.transitions.create("opacity", {
            duration: theme.transitions.duration.shorter,
          })};
          opacity: 0.25;
        }
      }
    }
  `}
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  ${({ theme }): SerializedStyles => css`
    padding: ${theme.spacing(1, 2, 3)};
  `}
`;
