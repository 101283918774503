import React from "react";
import { Button, TpLinkButton } from "../../buttons";
import { SectionContainer } from "../../containers";
import { useResponsive } from "../../hooks";
import { Link } from "../../links";
import { RichText } from "../../textFields";
import { getPathname, removeWhitespaces } from "../../utils";
import {
  AccordionContainer,
  Heading,
  LinkWrapper,
  Question,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledExpandMoreIcon,
} from "./styles";

export type PpTextAccordion = {
  ctaLink?: TpLinkButton;
  data: Array<{
    answer: string;
    question: string;
  }>;
  description?: string;
  sectionName?: string;
  title: string;
};

export function TextAccordion({
  ctaLink,
  data,
  description,
  sectionName,
  title,
  ...rest
}: PpTextAccordion): React.ReactElement | null {
  const r = useResponsive();

  if (data.length === 0) return null;

  return (
    <SectionContainer {...rest}>
      <AccordionContainer>
        <Heading
          description={description}
          sectionName={sectionName}
          standardWidth={false}
          textAlign="center"
          title={title}
        />
        {data.map((item) => (
          <StyledAccordion
            key={item.question}
            disableGutters
            square
            elevation={0}
          >
            <StyledAccordionSummary
              aria-controls={`${removeWhitespaces(item.question)}-content`}
              expandIcon={<StyledExpandMoreIcon />}
              id={`${removeWhitespaces(item.question)}-id`}
            >
              <Question
                color="primary"
                component="h3"
                variant={r({ md: "h3", xs: "h5" })}
              >
                {item.question}
              </Question>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <RichText
                color="primary"
                text={item.answer}
                variant={r({ md: "body1", xs: "body2" })}
              />
            </StyledAccordionDetails>
          </StyledAccordion>
        ))}
        {ctaLink && (
          <LinkWrapper>
            <Link to={getPathname(ctaLink.page.url)}>
              <Button color="secondary" variant="contained">
                {ctaLink.title}
              </Button>
            </Link>
          </LinkWrapper>
        )}
      </AccordionContainer>
    </SectionContainer>
  );
}
