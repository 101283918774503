import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { SectionHeading } from "../containers";

export const PackageWrapper = styled(Grid)`
  max-width: 500px;
`;

export const ToggleBtnGroupContainer = styled(Grid)`
  ${({ theme }): SerializedStyles => css`
    max-width: 500px !important;
    width: 100%;
    .MuiToggleButtonGroup-root {
      width: 100%;
      > button {
        flex: 1;
      }
    }
    ${theme.breakpoints.up("lg")} {
      margin: 0 auto !important;
      max-width: none !important;
      width: 950px !important;
    }
  `}
`;

export const Heading = styled(SectionHeading)`
  ${({ theme }): SerializedStyles => css`
    ${theme.breakpoints.between("sm", "lg")} {
      max-width: 500px;
    }
    ${theme.breakpoints.up("lg")} {
      max-width: 66%;
    }
  `}
`;
